import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/shared/service/api.service';
import { UserTypes } from './models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersChanges: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private api: ApiService,
  ) { }

  userGridColumns = [
    "fullName",
    "phone",
    "email",
    "type",
    "groups",
    "status",
    "twofa",
    "lastLogin"
  ]
  adminUserGridColumns = [
    "fullName",
    "phone",
    "email",
    "type",
    "status",
    "lastLogin"
  ]
  userGridFilters = {
    fullName: '',
  }


  initDetail = (userId: string) => this.api.get('user/ui/detail/initialise', { userId })

  initList = () => this.api.get('user/ui/list/grid', {})

  initRoles = (clientType:any, clientId: string) => this.api.get('role/ui/roles/getroles', { clientType, clientId })

  sendConfirmationEmail = (userName: any,password:any) => this.api.post('open/ui/user/confirmationEmail', { userName,password })

  create = (formValue) => this.api.post('user/ui/create/save', formValue)

  recreate = (formValue) => this.api.post('user/ui/create/recreate', formValue)

  checkExists = (email) => this.api.get(`user/ui/create/exists/${email}`, {})

  update = (formValue) => this.api.post('user/ui/edit/save', formValue)

  delete = (userId) => this.api.post(`user/ui/edit/delete/${userId}`, {})

  session = (email) => this.api.get(`user/login/${email}`, {})

  update2FA = (formValue) => this.api.post('user/ui/update/2FA', formValue)
  
  updateOrg2FA = (formValue) => this.api.post('user/ui/profile/saveOrg2FA', formValue)
 
  verifyUserToken = (token) => this.api.get(`open/ui/user/verifytoken`, {token})
  verifyResetPasswordLink = (token) => this.api.get(`open/ui/user/verifyLink`, {token})
  verifyUserSignInToken = (authIdPara) => this.api.get(`open/ui/user/addCookies`, {authIdPara})
  getAccessToken = (refreshToken) => this.api.get(`open/ui/user/getaccesstoken`, {refreshToken})
  bulkEmail = (users) => this.api.post('invites/ui/user/bulkinvite', users)


  translateUserType = (userType) => {
    return UserTypes[userType]
  }

  // getUserList = () => {
  //   console.log('inside get Clients');
  //   setTimeout(() => {
  //     console.log('Client Data === ', this.testdata);
  //     this.usersChanges.next(this.testdata);
  //   }, 1000);
  // }

  // testdata = [
  //   {
  //     "id": "1111",
  //     "name": "Luke Mcwha",
  //     "email": "luke@bento.soutions",
  //     "phone": "0977 234 238",
  //     "userGroups": ["admin", "manager"],
  //     "status": 'active'
  //   },
  // ]

}
